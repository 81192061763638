import React, { useEffect } from 'react';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { useNotificationContext } from './contexts/NotificationContext';
import Notifications from './components/Notifications';
import './App.css';
import { AuthProvider } from './contexts/AuthContext';
import { QuestsProvider } from './contexts/QuestsContext';
import { useAppInitialization } from './hooks/useAppInitialization';
import { appRoutes, lazyComponents } from './routes/appRoutes';
import ConditionalRenderer from './components/ConditionalRenderer';
import AppLoader from './components/AppLoader';

const AppContent: React.FC = () => {
  const { notification, hideNotification } = useNotificationContext();
  const location = useLocation();
  const { isInitializing, isLoading, isError, error, isTelegramAvailable, isValidEnvironment } =
    useAppInitialization();

  return (
    <ConditionalRenderer
      isInitializing={isInitializing}
      isLoading={isLoading}
      isError={isError}
      error={error}
      isTelegramAvailable={isTelegramAvailable}
      isValidEnvironment={isValidEnvironment}
    >
      <React.Suspense fallback={<AppLoader />}>
        <Routes location={location}>
          {appRoutes.map((route) => {
            const LazyComponent = lazyComponents[route.componentName];
            return <Route key={route.path} path={route.path} element={<LazyComponent />} />;
          })}
          <Route path="*" element={<Navigate to="/home" replace />} />
        </Routes>
      </React.Suspense>
      <Notifications
        type={notification.type}
        message={notification.message}
        isVisible={notification.isVisible}
        onClose={hideNotification}
      />
    </ConditionalRenderer>
  );
};

const App: React.FC = () => {
  return (
    <AuthProvider>
      <QuestsProvider>
        <AppContent />
      </QuestsProvider>
    </AuthProvider>
  );
};

export default App;
