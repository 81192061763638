import React, { useEffect } from 'react';
import { useSpring, animated, config } from 'react-spring';

interface NotificationProps {
  type: 'success' | 'error';
  message: string;
  isVisible: boolean;
  onClose: () => void;
  duration?: number;
}

const Notification: React.FC<NotificationProps> = ({
  type,
  message,
  isVisible,
  onClose,
  duration = 3000,
}) => {
  const [styles, api] = useSpring(() => ({
    opacity: 0,
    y: -100,
    config: config.stiff,
  }));

  useEffect(() => {
    if (isVisible) {
      api.start({ opacity: 1, y: 0 });
      const timer = setTimeout(onClose, duration);
      return () => clearTimeout(timer);
    } else {
      api.start({ opacity: 0, y: -100 });
    }
  }, [isVisible, api, onClose, duration]);

  return (
    <animated.div
      style={styles}
      className={`fixed top-4 left-4 right-4 z-50 ${type === 'success' ? 'bg-app-green' : 'bg-app-red-intense'} text-white p-4 rounded-xl w-[calc(100%-32px)] flex items-center`}
    >
      {type === 'success' ? (
        <>
          <svg
            width="24"
            height="24"
            className="shrink-0"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.665 3.5C5.135 3.5 3.5 5.233 3.5 7.916V16.084C3.5 18.767 5.135 20.5 7.665 20.5H16.333C18.864 20.5 20.5 18.767 20.5 16.084V7.916C20.5 5.233 18.864 3.5 16.334 3.5H7.665ZM16.333 22H7.665C4.276 22 2 19.622 2 16.084V7.916C2 4.378 4.276 2 7.665 2H16.334C19.723 2 22 4.378 22 7.916V16.084C22 19.622 19.723 22 16.333 22Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M10.8134 15.1229C10.6224 15.1229 10.4294 15.0499 10.2834 14.9029L7.90945 12.5299C7.61645 12.2369 7.61645 11.7629 7.90945 11.4699C8.20245 11.1769 8.67645 11.1769 8.96945 11.4699L10.8134 13.3119L15.0294 9.09695C15.3224 8.80395 15.7964 8.80395 16.0894 9.09695C16.3824 9.38995 16.3824 9.86395 16.0894 10.1569L11.3434 14.9029C11.1974 15.0499 11.0054 15.1229 10.8134 15.1229Z"
              fill="white"
            />
          </svg>
          <span className="ml-2 font-bold text-base leading-6 font-roboto">{message}</span>
        </>
      ) : (
        <>
          <svg
            width="24"
            height="24"
            className="shrink-0"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M9.60253 15.1367C9.41053 15.1367 9.21853 15.0637 9.07253 14.9167C8.77953 14.6237 8.77953 14.1497 9.07253 13.8567L13.8645 9.06472C14.1575 8.77172 14.6315 8.77172 14.9245 9.06472C15.2175 9.35772 15.2175 9.83172 14.9245 10.1247L10.1325 14.9167C9.98653 15.0637 9.79453 15.1367 9.60253 15.1367Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M14.3966 15.1396C14.2046 15.1396 14.0126 15.0666 13.8666 14.9196L9.07058 10.1226C8.77758 9.82965 8.77758 9.35565 9.07058 9.06265C9.36458 8.76965 9.83858 8.76965 10.1306 9.06265L14.9266 13.8596C15.2196 14.1526 15.2196 14.6266 14.9266 14.9196C14.7806 15.0666 14.5876 15.1396 14.3966 15.1396Z"
              fill="white"
            />
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M7.665 3.5C5.135 3.5 3.5 5.233 3.5 7.916V16.084C3.5 18.767 5.135 20.5 7.665 20.5H16.333C18.864 20.5 20.5 18.767 20.5 16.084V7.916C20.5 5.233 18.864 3.5 16.334 3.5H7.665ZM16.333 22H7.665C4.276 22 2 19.622 2 16.084V7.916C2 4.378 4.276 2 7.665 2H16.334C19.723 2 22 4.378 22 7.916V16.084C22 19.622 19.723 22 16.333 22Z"
              fill="white"
            />
          </svg>
          <span className="ml-2 font-normal text-base leading-6 font-roboto">{message}</span>
        </>
      )}
    </animated.div>
  );
};

export default Notification;
