import React, { createContext, useContext, useState, useCallback } from 'react';
import { MappedTasks, Task } from '../types/common';

interface QuestsContextType {
  modalState: {
    isOpen: boolean;
    questData: MappedTasks | Task | null;
  };
  setModalState: React.Dispatch<
    React.SetStateAction<{
      isOpen: boolean;
      questData: MappedTasks | Task | null;
    }>
  >;
  handleModalClose: () => void;
}

const QuestsContext = createContext<QuestsContextType | undefined>(undefined);

export const QuestsProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [modalState, setModalState] = useState<{
    isOpen: boolean;
    questData: MappedTasks | Task | null;
  }>({
    isOpen: false,
    questData: null,
  });

  const handleModalClose = useCallback(() => {
    setModalState({ isOpen: false, questData: null });
  }, []);

  return (
    <QuestsContext.Provider value={{ modalState, setModalState, handleModalClose }}>
      {children}
    </QuestsContext.Provider>
  );
};

export const useQuestsContext = () => {
  const context = useContext(QuestsContext);
  if (context === undefined) {
    throw new Error('useQuestsContext must be used within a QuestsProvider');
  }
  return context;
};
