import React, {
  createContext,
  useContext,
  useReducer,
  useCallback,
  ReactNode,
  useMemo,
  useRef,
} from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { LoginData, LoginResponse, User } from '../types/api';
import { API_BASE_URL, LOGIN_URL } from '../constants/common';

interface AuthState {
  user: User | null;
  isLoading: boolean;
  isError: boolean;
  error: string | null;
  isAuthenticated: boolean;
}

type AuthAction =
  | { type: 'LOGIN_START' }
  | { type: 'LOGIN_SUCCESS'; payload: User }
  | { type: 'LOGIN_FAILURE'; payload: string }
  | { type: 'LOGOUT' }
  | { type: 'UPDATE_USER'; payload: Partial<User> };

interface AuthContextType extends AuthState {
  login: (loginData: LoginData, options?: any) => Promise<void>;
  logout: () => void;
  authFetch: (url: string, options?: RequestInit) => Promise<Response>;
  checkDailyStreak: () => Promise<{ isDailyClaimed: boolean; currentStreakDay: number }>;
  claimDailyReward: () => Promise<void>;
  updateUser: (userData: Partial<User>) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

let accessToken: string | null = null;

const initialState: AuthState = {
  user: null,
  isLoading: false,
  isError: false,
  error: null,
  isAuthenticated: false,
};

function authReducer(state: AuthState, action: AuthAction): AuthState {
  switch (action.type) {
    case 'LOGIN_START':
      return { ...state, isLoading: true, isError: false, error: null };
    case 'LOGIN_SUCCESS':
      return {
        ...state,
        user: action.payload,
        isLoading: false,
        isAuthenticated: true,
        isError: false,
        error: null,
      };
    case 'LOGIN_FAILURE':
      return {
        ...state,
        isLoading: false,
        isError: true,
        error: action.payload,
        user: null,
        isAuthenticated: false,
      };
    case 'LOGOUT':
      return { ...initialState };
    case 'UPDATE_USER':
      return {
        ...state,
        user: state.user ? { ...state.user, ...action.payload } : null,
      };
    default:
      return state;
  }
}

export const AuthProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const queryClient = useQueryClient();
  const [state, dispatch] = useReducer(authReducer, initialState);
  const loginAttemptsRef = useRef(0);
  const callTimestampsRef = useRef<number[]>([]);

  // Rate-limiting function
  const rateLimit = useCallback(async () => {
    const now = Date.now();
    const timestamps = callTimestampsRef.current;

    // Remove timestamps older than 500ms
    while (timestamps.length > 0 && now - timestamps[0] > 500) {
      timestamps.shift();
    }

    if (timestamps.length >= 3) {
      // Need to wait
      const waitTime = 500 - (now - timestamps[0]);
      await new Promise((resolve) => setTimeout(resolve, waitTime));
      // After waiting, proceed without recursive call
    }

    // Allowed to proceed
    timestamps.push(Date.now());
  }, []);

  const loginMutation = useMutation({
    mutationFn: async (loginData: LoginData): Promise<LoginResponse> => {
      const response = await fetch(`${API_BASE_URL}${LOGIN_URL}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(loginData),
      });
      if (!response.ok) throw new Error('Login failed');
      return response.json();
    },
    onMutate: () => {
      loginAttemptsRef.current += 1;
      dispatch({ type: 'LOGIN_START' });
    },
    onSuccess: (data) => {
      accessToken = data.token;
      dispatch({ type: 'LOGIN_SUCCESS', payload: data.user });
      queryClient.setQueryData(['user'], data.user);
    },
    onError: (err) => {
      dispatch({
        type: 'LOGIN_FAILURE',
        payload: err instanceof Error ? err.message : 'Login failed',
      });
      accessToken = null;
    },
  });

  const login = useCallback(
    async (loginData: LoginData, options?: any): Promise<void> => {
      if (loginAttemptsRef.current < 3) {
        try {
          await rateLimit();
          await loginMutation.mutateAsync(loginData, options);
        } catch (error) {
          console.error('Login error:', error);
        }
      } else {
        dispatch({
          type: 'LOGIN_FAILURE',
          payload: 'Maximum login attempts reached. Please try again later.',
        });
      }
    },
    [loginMutation, rateLimit]
  );

  const authFetch = useCallback(
    async (url: string, options: RequestInit = {}): Promise<Response> => {
      await rateLimit();
      if (!accessToken) throw new Error('Not authenticated');
      const response = await fetch(url, {
        ...options,
        headers: { ...options.headers, Authorization: `Bearer ${accessToken}` },
      });
      if (response.status === 401) {
        dispatch({ type: 'LOGOUT' });
        throw new Error('Authentication failed');
      }
      return response;
    },
    [rateLimit]
  );

  const logout = useCallback(() => {
    accessToken = null;
    dispatch({ type: 'LOGOUT' });
    queryClient.clear();
  }, [queryClient]);

  const updateUser = useCallback(
    (userData: Partial<User>) => {
      if (state.user) {
        const updatedUser = { ...state.user, ...userData };
        dispatch({ type: 'UPDATE_USER', payload: updatedUser });
        queryClient.setQueryData(['user'], updatedUser);
      }
    },
    [queryClient, state.user]
  );

  const checkDailyStreak = useCallback(async (): Promise<{
    isDailyClaimed: boolean;
    currentStreakDay: number;
  }> => {
    try {
      const response = await authFetch(`${API_BASE_URL}/auth/daily-streak`);
      return await response.json();
    } catch (error) {
      console.error('Error checking daily streak:', error);
      return { isDailyClaimed: false, currentStreakDay: 0 };
    }
  }, [authFetch]);

  const claimDailyReward = useCallback(async (): Promise<void> => {
    try {
      const response = await authFetch(`${API_BASE_URL}/auth/claim-daily-reward`, {
        method: 'POST',
      });
      const data = await response.json();
      updateUser(data.user);
    } catch (error) {
      console.error('Error claiming daily reward:', error);
    }
  }, [authFetch, updateUser]);

  const value = useMemo(
    () => ({
      ...state,
      login,
      logout,
      authFetch,
      checkDailyStreak,
      claimDailyReward,
      updateUser,
    }),
    [state, login, logout, authFetch, checkDailyStreak, claimDailyReward, updateUser]
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export const useAuth = (): AuthContextType => {
  const context = useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
};
