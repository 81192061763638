import { useState, useEffect } from 'react';
import { ENV, USERS_WITH_PRIVILEGES } from '../constants/common';

interface TelegramWebApp {
  initDataUnsafe: {
    user?: {
      id: number;
      username?: string;
    };
    start_param?: string;
  };
  ready: () => void;
  expand: () => void;
  BackButton: {
    show: () => void;
    hide: () => void;
    onClick: (callback: () => void) => void;
    offClick: (callback: () => void) => void;
  };
  showAlert: (message: string) => void;
  openInvoice: (url: string) => void;
  platform: string;
  initData: string;
  colorScheme: string;
  onEvent: (eventType: string, callback: (event: any) => void) => void;
  offEvent: (eventType: string, callback: (event: any) => void) => void;
}

interface WebView {
  receiveEvent?: (eventName: string, eventData: any) => void;
}

declare global {
  interface Window {
    Telegram?: {
      WebApp: TelegramWebApp;
      WebView: WebView;
    };
  }
}

export const useTelegramInit = () => {
  const [isTelegramAvailable, setIsTelegramAvailable] = useState(false);
  const [isInitializing, setIsInitializing] = useState(true);
  const [telegramInitData, setTelegramInitData] = useState<any>(null);
  const [isValidEnvironment, setIsValidEnvironment] = useState(false);

  useEffect(() => {
    const initializeTelegram = () => {
      const tg = window.Telegram?.WebApp;
      if (!tg || !isValidTelegramWebApp(tg)) {
        finishInitialization(false);
        return;
      }

      setupTelegramWebApp(tg);

      const isValid = validateEnvironment(tg);
      if (!isValid) {
        finishInitialization(false);
        return;
      }

      finishInitialization(true);
    };

    initializeTelegram();
  }, []);

  const isValidTelegramWebApp = (tg: TelegramWebApp): boolean => {
    return Boolean(tg.initData && tg.colorScheme && tg.BackButton && tg.openInvoice);
  };

  const validateEnvironment = (tg: TelegramWebApp): boolean => {
    if (
      ['tdesktop', 'android', 'ios'].includes(tg.platform) ||
      (tg.initDataUnsafe.user &&
        tg.initDataUnsafe.user.username &&
        USERS_WITH_PRIVILEGES.includes(tg.initDataUnsafe.user?.username))
    ) {
      return true;
    }
    return isValidWebEnvironment();
  };

  const isValidWebEnvironment = (): boolean => {
    const isIframe = window !== window.parent;
    const hasLimitedNavigator = !('plugins' in navigator);
    const hasExpectedReferrer =
      document.referrer.includes('telegram.org') || document.referrer.includes('t.me');
    return isIframe && hasLimitedNavigator && hasExpectedReferrer;
  };

  const setupTelegramWebApp = (tg: TelegramWebApp) => {
    tg.ready();
    tg.expand();
    setIsTelegramAvailable(true);

    const { user, start_param } = tg.initDataUnsafe;
    const initData = tg.initData;

    if (user && ENV === 'development') {
      setTelegramInitData(user);
    }

    if (initData && ENV === 'production') {
      setTelegramInitData(initData);
    }

    if (start_param && !start_param.startsWith('hot-')) {
      localStorage.setItem('originalRefCode', start_param);
    }
  };

  const finishInitialization = (isValid: boolean) => {
    setIsValidEnvironment(isValid);
    setIsInitializing(false);
  };

  return { isTelegramAvailable, isInitializing, telegramInitData, isValidEnvironment };
};
