import React from 'react';
import AppLoader from '../components/AppLoader';
import { IS_MAINTENANCE_ENABLED } from '../constants/common';

interface ConditionalRenderProps {
  isInitializing: boolean;
  isLoading: boolean;
  isError: boolean;
  error: string | null;
  isTelegramAvailable: boolean;
  isValidEnvironment: boolean;
  children: React.ReactNode;
}

const ConditionalRenderer: React.FC<ConditionalRenderProps> = ({
  isInitializing,
  isLoading,
  isError,
  error,
  isTelegramAvailable,
  isValidEnvironment,
  children,
}) => {
  if (IS_MAINTENANCE_ENABLED) {
    const LazyMaintenancePage = React.lazy(() => import('../pages/MaintenancePage'));
    return <LazyMaintenancePage />;
  }

  if (isInitializing || isLoading) {
    return <AppLoader />;
  }

  if (isError) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="p-6 bg-white rounded shadow-md">
          <h2 className="mb-4 text-2xl font-bold">Login Error</h2>
          <p>{error || 'Failed to log in. Please try again later.'}</p>
        </div>
      </div>
    );
  }

  if (!isTelegramAvailable) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="p-6 bg-white rounded shadow-md">
          <h2 className="mb-4 text-2xl font-bold">Welcome to Statbet Bot APP</h2>
          <p>This app is designed to work within Telegram.</p>
        </div>
      </div>
    );
  }

  if (!isValidEnvironment) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-app-dark">
        <div className="flex flex-col items-center relative gap-4">
          <div className="absolute h-[calc(100%-40px)] w-full z-1 top-[20px] bg-gradient-to-r from-[#00E8FF] to-[#C300FF] blur-[100px]"></div>
          <h2 className="text-2xl font-orbitron relative z-10 text-white font-extrabold">
            Best experienced on mobile.
          </h2>
          <p className="text-2xl font-orbitron text-white font-extrabold relative z-10">
            Farm on your phone.
          </p>
        </div>
      </div>
    );
  }

  return <>{children}</>;
};

export default ConditionalRenderer;
