import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { useTelegramInit } from './useInittelegram';
import { useNotificationContext } from '../contexts/NotificationContext';
import { ENV } from '../constants/common';

export const useAppInitialization = () => {
  const { user, isLoading, isError, login, error, checkDailyStreak } = useAuth();
  const { isTelegramAvailable, isInitializing, telegramInitData, isValidEnvironment } =
    useTelegramInit();
  const { showNotification } = useNotificationContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!isInitializing && isTelegramAvailable && telegramInitData && !user && !isLoading) {
      const referralCode = localStorage.getItem('originalRefCode') || undefined;
      const loginBody =
        ENV === 'development'
          ? {
              telegramId: telegramInitData.id.toString(),
              username: telegramInitData.username,
              referralCode,
            }
          : {
              telegramInitData,
              referralCode,
            };
      (async () => {
        try {
          await login(loginBody, {
            onSuccess: async () => {
              localStorage.removeItem('originalRefCode');
            },
            onError: (error: any) => {
              console.error('Login error:', error);
              localStorage.removeItem('originalRefCode');
              showNotification('error', 'Failed to log in. Please try again later.');
            },
          });
        } catch (err) {
          console.error('Login error:', err);
          localStorage.removeItem('originalRefCode');
          showNotification('error', 'Failed to log in. Please try again later.');
        }
      })();
    }
  }, [
    isInitializing,
    isTelegramAvailable,
    telegramInitData,
    user,
    isLoading,
    login,
    showNotification,
    checkDailyStreak,
    navigate,
  ]);

  return {
    isInitializing,
    isLoading,
    isError,
    error,
    isTelegramAvailable,
    isValidEnvironment,
  };
};
