import React from 'react';
import { LoaderImage } from '../images';

const AppLoader: React.FC = () => {
  return (
    <div className="flex flex-col justify-center items-center h-screen bg-app-dark">
      <img src={LoaderImage} alt="StatBet bot loader" className="w-full" />
    </div>
  );
};

export default AppLoader;
