import React from 'react';

type LazyComponent = React.LazyExoticComponent<React.ComponentType<any>>;

export const lazyComponents: Record<string, LazyComponent> = {
  Home: React.lazy(() => import('../pages/HomePage')),
  Tasks: React.lazy(() => import('../pages/TasksPage')),
  Bets: React.lazy(() => import('../pages/BetsPage')),
  BetsHistory: React.lazy(() => import('../pages/BetsHistoryPage')),
  Frens: React.lazy(() => import('../pages/FrensPage')),
  Leaderboard: React.lazy(() => import('../pages/LeaderboardPage')),
};

export const appRoutes = [
  { path: '/home', componentName: 'Home' },
  { path: '/tasks', componentName: 'Tasks' },
  { path: '/bets', componentName: 'Bets' },
  { path: '/bets/history', componentName: 'BetsHistory' },
  { path: '/frens', componentName: 'Frens' },
  { path: '/leaderboard', componentName: 'Leaderboard' },
] as const;

export type AppRoute = (typeof appRoutes)[number];
