export const API_BASE_URL =
  import.meta.env.VITE_API_URL || 'https://statbet-stage-api-te88p.ondigitalocean.app/api/v1';
export const ENV = import.meta.env.VITE_ENV || 'development';
export const IS_MAINTENANCE_ENABLED = false;
export const LOGIN_URL = ENV === 'development' ? '/auth/login/unsafe' : '/auth/login';
export const USERS_WITH_PRIVILEGES = [
  'rodionbulakh',
  'vertumion',
  'mbobma',
  'karymabdelrakhman',
  'reactness',
  'schokolade0921',
];
